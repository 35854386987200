/**
 * Functionality for sharing on social networks.
 *
 * @module feature/Social
 */
import querystring from 'querystring';

/**
 * Enables sharing on social networks via data attributes.
 *
 * @requires querystring
 * @memberof module:feature/Social
 * @version 2.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class ShareBar {

    /**
     * Upgrades DOM element.
     * @param {DOMElement} element DOM element to upgrade.
     */
    constructor(element) {
        this.$element = element[0];
        this.$shareButtons = null;

        this.windowObjectReference = null;
    }

    /**
     * Configures after load.
     */
    init() {
        this.$shareButtons = Array.from(this.$element.querySelectorAll('.js-share-bar-btn'));
        if (this.$shareButtons.length > 0) {
            this.$shareButtons.forEach((button) => {
                let url = null;
                switch(button.dataset.service) {
                    case 'facebook': {
                        url = this.createFacebookUrl(button);
                        break;
                    }
                    case 'twitter': {
                        url = this.createTwitterUrl(button);
                        break;
                    }
                    case 'linkedin': {
                        url = this.createLinkedInUrl(button);
                        break;
                    }
                    case 'googleplus':
                    case 'google-plus': {
                        url = this.createGooglePlusUrl(button);
                        break;
                    }
                    case 'pinterest': {
                        url = this.createPinterestUrl(button);
                        break;
                    }
                    case 'vk': {
                        url = this.createVKontakteUrl(button);
                        break;
                    }
                    default: {
                        //console.warn('[ShareBar] No service attribute set for share button: ', button);
                        break;
                    }
                }

                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (url) {
                        this.openPopup(url, '_blank');
                        return false;
                    }
                }, false);
            });
        }
    }

    /**
     * Opens or focusses a popup window.
     * @param {String} url Url of page to open in popup
     * @param {String} title Title of window
     * @param {String} prop Window properties
     */
    openPopup(url, title, prop = 'width=500, height=620, resizable, scrollbars=yes, status=1, centerscreen=1, chrome=yes') {

        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const left = ((width / 2) - (500 / 2)) + dualScreenLeft;
        const top = ((height / 2) - (620 / 2)) + dualScreenTop;

        prop += `, top=${top}, left=${left}`;

        if (this.windowObjectReference === null || this.windowObjectReference.closed) {
            this.windowObjectReference = window.open(url, title, prop);
        } else {
            this.windowObjectReference.focus();
        }
    }

    createFacebookUrl(button) {
        const shareUrl = 'https://www.facebook.com/sharer/sharer.php';
        const params = {
            u: button.dataset.url
            //appid: '2142952709365629',
        };

        // https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.smashingmagazine.com%2F2019%2F05%2Ffuture-design-voice-prototypes%2F
        return `${shareUrl}?${querystring.stringify(params)}`;
    }

    createTwitterUrl(button) {
        const shareUrl = 'https://twitter.com/intent/tweet';
        const params = {
            text: button.dataset.title,
            url: button.dataset.url,
            related: button.dataset.url
        };

        // https://twitter.com/intent/tweet?text=This%20is%20the%20default%20share%20bar%20description&url=https%3A%2F%2Fdaf.localhost.efocus.local%2Fen%2Ftrucks%23.W6yio1jKPx8.twitter&related=
        return `${shareUrl}?${querystring.stringify(params)}`;
    }

    createGooglePlusUrl(button) {
        const shareUrl = 'https://plus.google.com/share';
        const params = {
            url: button.dataset.url,
            title: button.dataset.title,
            description: button.dataset.url
        };
        return `${shareUrl}?${querystring.stringify(params)}`;
    }

    createLinkedInUrl(button) {
        const shareUrl = 'https://www.linkedin.com/sharing/share-offsite';
        const params = {
            url: button.dataset.url
        };

        // https://www.linkedin.com/sharing/share-offsite/?url=https://www.smashingmagazine.com/2019/05/future-design-voice-prototypes/
        return `${shareUrl}?${querystring.stringify(params)}`;
    }

    createPinterestUrl(button) {
        const shareUrl = 'https://pinterest.com/pin/create/button/';
        const params = {
            description: button.dataset.title,
            media: button.dataset.media,
            url: button.dataset.url
        };
        // https://pinterest.com/pin/create/button/?url=https%3A//www.daf.com/-/media/images/daf-trucks/trucks/euro-3-5/range/daf-model-range-euro-3-5-2015142.jpg?mw=1280%26amp;hash=9E2EFF7FA62EA6EE698E66B33EF3131018C2776E&media=https%3A//www.daf.com/en&description=beschrijving
        return `${shareUrl}?${querystring.stringify(params)}`;
    }

    createVKontakteUrl(button) {
        const shareUrl = 'https://vk.com/share.php';
        const params = {
            display: 'widget',
            title: button.dataset.title,
            description: button.dataset.url,
            image: button.dataset.media,
            url: button.dataset.url
        };

        // https%3A%2F%2Fvk.com%2Fshare.php%3Furl%3Dhttps%253A%252F%252Fdaf.localhost.efocus.local%252Fen%252Ftrucks%2523.W6ypESJzxFI.vk%26title%3DThis%2Bis%2Bthe%2Bdefault%2Bshare%2Bbar%2Bdescription%26description%3D%26image%3Dhttps%253A%252F%252Fdaf.localhost.efocus.local%253A443%252F-%252Fmedia%252Fdaf%252Fimages%252Fnews%252Finternational-truck-of-the-year-2018-daf-new-xf-new-cf-1920.jpg&display=widget
        return `${shareUrl}?${querystring.stringify(params)}`;
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: ShareBar,
    classAsString: 'ShareBar',
    cssClass: 'js-share-bar'
});

export default ShareBar;